:root {
  --husk: #30262d;
  --darkHusk: #191317;
  --cobalt: #555b6e;
  --darkRock: #6d6d6d;
  --rock: #a6a4a2;
  --sand: #ddd9d4;
  --darkSand: #d4d0cb;
  --foam: #f2efea;
  --darkFoam: #e8e5e0;
  --cream: #f8f7f4;
  --lightSea: #def8f9;
  --midSea: #c2efed;
  --sea: #66d7d1;
  --darkSea: #5ec6c0;
  --seaweed: #3aa09b;
  --deepSea: #2e7f7b;
  --watermelon: #ea526f;
  --darkWatermelon: #d74b66;
  --orange: #fbbc05 !important;
  --darkOrange: #e9b004;
  --red: #ea526f !important;
  --darkRed: #d74b66 !important;
  --background: #f7f7f7;
  --backgroundContrast: #f1f1f1;
  --white: #ffffff;
  --grey01: #a6a4a2;
  --grey02: #ddd9d4;
  --grey03: #f2efea;
  --grey05: #f7f6f6;
  --gainsboro: #e3e3e3;
}

// Override bootstrap semantic colour names
$grey05: #f7f6f6;
$husk: #30262d;
$primary: #66d7d1;
$primary-light: #def8f9;
$secondary: #ddd9d4;
$info: #66d7d1;
$danger: #ea526f;
$text-muted: #a6a4a2;
